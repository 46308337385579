import React from 'react'

const About = () => {
  return (
    <div>
      <h1>박성훈</h1>
      <p>2000.09.25</p>
    </div>
  )
}

export default About